import Button from '@mui/material/Button';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { mergeProps, useLongPress } from 'react-aria';

interface Props {
  buttonType?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  normalButton: {
    borderWidth: (props: Props) => {
      if(props.buttonType === "primaryOutlined") return "3px";
      else if(props.buttonType === "darkBlueOutlined") return "3px";
      else if(props.buttonType === "blackOutlined") return "3px";
      else return "3px";   
    },
    borderStyle: 'solid',
    borderColor: (props: Props) => {
      if(props.buttonType === "primaryOutlined") return theme.colors.primaryBlue[500];
      else if(props.buttonType === "darkBlueOutlined") return "#003164";
      else if(props.buttonType === "blackOutlined") return theme.colors.black;
      else return "transparent";   
    },
    backgroundColor: (props: Props) => {
      if(props.buttonType === "primary") return theme.colors.primaryBlue[500];
      else if(props.buttonType === "secondary") return theme.colors.grey[200];
      else if(props.buttonType === "tertiary") return theme.colors.white;    
      else if(props.buttonType === "disabled") return theme.colors.grey[75];
      else if(props.buttonType === "chip") return theme.colors.grey[200];
      else if(props.buttonType === "filter") return theme.colors.white;
      else if(props.buttonType === "blue") return theme.colors.grey[250];
      else if(props.buttonType === "darkBlue") return "#003164";
      else if(props.buttonType === "darkBlueOutlined") return "transparent";
      else if(props.buttonType === "red") return "#FFF1F3";
      else if(props.buttonType === "yellow") return "#FFF2DA";
      else if(props.buttonType === "white") return theme.colors.white;
      else if(props.buttonType === "black") return theme.colors.black;
      else if(props.buttonType === "blackOutlined") return "transparent";
      else if(props.buttonType === "brand") return theme.colors.primaryBlue[500];
      else if(props.buttonType === "clear") return theme.colors.systemRed[500];
      else if(props.buttonType === "reacted") return theme.colors.primaryBlue[50];
      else if(props.buttonType === "saved") return theme.colors.systemRed[75];
      else return "transparent";     
    },
    color: (props: Props) => {
      if(props.buttonType === "text") return theme.colors.black;
      else return '';
    },
    padding: (props: Props) => {
      if(props.buttonType === "text") return "0px";
      else return "9px 21px";
    },
    borderRadius: "10px",
    height: (props: Props) => {
      if(props.buttonType === "text") return "";
      else return "42px";
    },
    minWidth: (props: Props) => {
      if(props.buttonType === "text") return "";
      else return "120px";
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    transition: 'all 0.25s',
    textTransform: 'none',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      fontSize: (props: Props) => {
        if(props.buttonType === "text") return "12px";
        else return "14px";
      },
      fontWeight: (props: Props) => {
        if(props.buttonType === "text") return "400";
        else return "500";
      },
      whiteSpace: "nowrap",
      color: (props: Props) => {
        if(props.buttonType === "primary") return theme.colors.alwaysWhite;
        else if(props.buttonType === "primaryOutlined") return theme.colors.primaryBlue[500];
        else if(props.buttonType === "secondary") return theme.colors.grey[800];
        else if(props.buttonType === "tertiary") return theme.colors.grey[800];
        else if(props.buttonType === "disabled") return theme.colors.grey[560];
        else if(props.buttonType === "chip") return theme.colors.grey[700];
        else if(props.buttonType === "filter") return theme.colors.grey[560];
        else if(props.buttonType === "cancel") return "#F44336";
        else if(props.buttonType === "info") return "#5AB8FF";
        else if(props.buttonType === "warning") return "#FFCC00";
        else if(props.buttonType === "blue") return theme.colors.primaryBlue[500];
        else if(props.buttonType === "darkBlue") return theme.colors.white;
        else if(props.buttonType === "darkBlueOutlined") return "#003164";
        else if(props.buttonType === "red") return "#EE576C";
        else if(props.buttonType === "yellow") return "#FFC632";
        else if(props.buttonType === "white") return theme.colors.black;
        else if(props.buttonType === "black") return theme.colors.white;
        else if(props.buttonType === "blackOutlined") return theme.colors.black;
        else if(props.buttonType === "brand") return theme.colors.alwaysWhite;
        else if(props.buttonType === "clear") return theme.colors.alwaysWhite;
        else if(props.buttonType === "reacted") return theme.colors.primaryBlue[400];
        else if(props.buttonType === "saved") return theme.colors.systemRed[400];
        else if(props.buttonType === "text") return 'inherit';
      },
      '& > svg': {
        display: (props: Props) => {
          if(props.buttonType === "text") return 'none';
          else return '';
        },
      },
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        if(props.buttonType === "primary") return theme.colors.primaryBlue[600];
        else if(props.buttonType === "secondary") return theme.colors.grey[300];
        else if(props.buttonType === "tertiary") return theme.colors.grey[125];
        else if(props.buttonType === "chip") return theme.colors.grey[200];
        else if(props.buttonType === "darkBlue") return "#013164";
        else if(props.buttonType === "brand") return theme.colors.primaryBlue[500];
        else if(props.buttonType === "clear") return theme.colors.systemRed[400];
        else if(props.buttonType === "white") return "#F2F2F2";
        else if(props.buttonType === "black") return "#111111";
        else if(props.buttonType === "reacted") return theme.colors.primaryBlue[100];
        else if(props.buttonType === "saved") return theme.colors.systemRed[100];
        else return 'transparent';
      },
      color: (props: Props) => {
        if(props.buttonType === "text") return theme.colors.primaryBlue[500];
        else return '';
      },
      boxShadow: (props: Props) => {
        if(props.buttonType === "chip") return "0px 5px 7px 0px rgba(158, 158, 158, 0.5)";
        else return 'none';
      },
      borderColor: (props: Props) => {
        if(props.buttonType === "primaryOutlined") return theme.colors.primaryBlue[600];
        else return '';
      }
    },
  },
}));

type NormalButtonType = {
  type?: 'button' | 'submit' | 'reset';
  buttonType?: 'primary' | 'primaryOutlined' | 'secondary' | 'tertiary' | 'chip' | 'brand' | 'clear' | 'filter' | 'blue' | 'darkBlue' | 'darkBlueOutlined' | 'red' | 'yellow' | 'white' | 'black' | 'blackOutlined' | 'disabled' | 'reacted' | 'text' | 'saved';
  className?: any;
  startIcon?: any;
  onClick?: any;
  onLongPress?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  children?: any;
  disabled?: boolean;
  disableRipple?: boolean;
  tabIndex?: any;
  dataCy?: string;
  customRef?: any;
  dataClarityMask?: 'true' | 'false';
  dataClarityUnmask?: 'true' | 'false';
};

const NormalButton: React.FunctionComponent<NormalButtonType> = ({type, buttonType = "primary", className, startIcon, onClick, onLongPress, onMouseEnter, onMouseLeave, children, disabled, disableRipple, tabIndex = 0, dataCy, customRef, dataClarityMask, dataClarityUnmask}) => {

  if(buttonType === "disabled") disabled = true;
  if(disabled) buttonType = "disabled";
  const classes = useStyles({
    buttonType
  });

  const { longPressProps } = useLongPress({
    onLongPress: (e: any) => {
      if(onLongPress) {
        onLongPress(e);
      }
    }
  });

  const handleOnClick = (e: any) => {
    if(onLongPress) {
      e.stopPropagation();
      e.preventDefault();
    }
    if(onClick) onClick(e);
  };

  const handleOnMouseEnter = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(onMouseEnter) onMouseEnter(e);
  };

  const handleOnMouseLeave = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(onMouseLeave) onMouseLeave(e);
  };
  
  return (
    <Button {...mergeProps(onLongPress ? longPressProps : null)} type={type} startIcon={buttonType === 'text' ? '' : startIcon} className={`${classes.normalButton} ${className}`} onClick={handleOnClick} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} disabled={disabled} disableFocusRipple={disableRipple} disableTouchRipple={disableRipple} tabIndex={parseInt(tabIndex)} data-cy={isCypress() ? dataCy : null} disableRipple={buttonType === 'text'} ref={customRef} data-clarity-mask={dataClarityMask} data-clarity-unmask={dataClarityUnmask}>
      <span>{children}</span>
    </Button>
  )
};

export default NormalButton;